

/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import Autosuggest from 'react-autosuggest';
import EditConfirmation from './EditConfirmation';
import { withRouter } from 'react-router';
import $ from 'jquery';
import editIcon from '../../images/icon-edit-active.png';
import request from '../../services/Service';
import i18n from 'i18next';
import { AgGridLocale } from '../../Utils/AgGridLocale';
import {connect} from  'react-redux';
import { inputValidator } from '../../Utils/InputElementValidation';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import downloadIcon from "../../images/icon-download.png";
const escapeRegexCharacters = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

const getSuggestionValue = suggestion => suggestion.OmidName;

const renderSuggestion = suggestion => <span>{suggestion.OmidName}</span>;

var OmidData = []

function CreateEditBox(params) {
    try {
        let eDiv = document.createElement("div");
    //   let groupId=params.colDef.cellRendererParams.groupId;
       
            eDiv.innerHTML = `<span><img src=${editIcon}></span>`;
       
        eDiv.className = "col-md-12";
        eDiv.width = "100%";
        return eDiv;
    }
    catch (error) {
        console.log("Error Encountered");
    }
}

class OMID extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            OmidGridData: [],
            isEdit: 1,
            errors: {},
            selectedRow: {},
            value: '',
            suggestions: [],
            OmidName: '',
            OMID: 0,

            Description: '',
            isEditPopUp: false,
            IsConfirmUpdate: false,
            isUpdateExisting: false,
            IsActive: true
        };
        this.ChangeIsActive = this.ChangeIsActive.bind(this);
        this.OnCancel = this.OnCancel.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.getSuggestions = this.getSuggestions.bind(this);
        this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
        this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
        this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
        this.populateValues = this.populateValues.bind(this);
        this.reSizeGrid = this.reSizeGrid.bind(this);
        this.SaveOmidDetails = this.SaveOmidDetails.bind(this);
        this.closeNavigationPopUp = this.closeNavigationPopUp.bind(this);
        this.passValue = this.passValue.bind(this);
        this.formatActive = this.formatActive.bind(this);
        this.onSearch = this.onSearch.bind(this);
    }

    componentWillMount() {
        try {
            this.setState({ OmidGridData: OmidData });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onGridReady(params) {
        try {
            $("#loader-div").hide();
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;

            var objThis = this;
            var SearchedString = "";
            params.api.showLoadingOverlay();
            request("/Common/BindOMID", 'POST')
         //   request("/MasterScreens/GetLever?OMID=0&GroupID="+this.props.groupId+"&SearchedString=" + SearchedString + "&LanguageID=" +this.props.languageId,'GET')   
            .then(res => {
                if(res.length!==0){

                    params.api.hideOverlay();
                    objThis.setState({ OmidGridData: res });
                    console.log(res,"response1")
                    $("#loader-div").hide();
                }

                else{
                    params.api.showNoRowsOverlay();
                }
                })
                .catch(error => {
                    console.log("Error Encountered");
                });
            params.api.sizeColumnsToFit();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    // onCellClicked(e) {
    //     try {
    //         if (e.colDef.headerName == i18n.t('edit'))
    //             {
    //             this.populateValues(e.data);
    //             console.log(e.data)
    //         }
    //     }
    //     catch (error) {
    //         console.log("Error Encountered");
    //     }
    // }

    // populateValues(omid) {
    //     try {
    //         this.setState({
    //             isEdit: 2,
    //             selectedRow: omid,
    //             OMID: omid.OMID,
    //        //     IsActive: omid.IsActive,
    //             value: omid.OMID,
    //         //    OmidName: omid.OmidName,
    //           //  Description: omid.Description,
    //             isUpdateExisting: true
    //         });
    //     }
    //     catch (error) {
    //         console.log("Error Encountered");
    //     }
    // }
 
    onCellClicked(e) {
        try {
            // Check if the column header name matches the 'edit' action
            if (e.colDef.headerName === i18n.t('edit')) {
                this.populateValues(e.data);
                console.log("Data clicked:", e.data); // Logs the clicked row data for debugging
            }
        } catch (error) {
            console.error("Error Encountered in onCellClicked:", error);
        }
    }
    
    populateValues(omid) {
        try {
            console.log("Received OMID:", omid);  // Logs the entire object for debugging
    
            // Validate omid data
            if (omid && omid.OMID !== undefined) {
                let omidValue = omid.OMID;
                console.log("OMID Value before processing:", omidValue); // Log OMID value
    
                // Ensure OMID is treated as a string (if it's a number, convert it to a string)
                if (typeof omidValue !== 'string') {
                    omidValue = String(omidValue);  // Convert to string
                }
    
                // Trim the value if it's a string
                omidValue = omidValue.trim();
                console.log("OMID Value after trim:", omidValue); // Log after trimming
    
                // Update state with the OMID value and other necessary fields
                this.setState({
                    isEdit: 2,
                    selectedRow: omid,
                    OMID: omid.OMID,
                    value: omidValue,
                    isUpdateExisting: true
                });
            } else {
                console.warn("Invalid data for OMID:", omid);  // Log invalid data for debugging
            }
        } catch (error) {
            console.error("Error Encountered in populateValues:", error);  // Log any errors during this process
        }
    }
    
    
    
    
    

    
    
    ChangeIsActive(event) {
        try {
            this.setState({ IsActive: event.target.checked });
            this.props.editFun(true);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onInputChange(eve) {
        try {
            let attr = eve.target.getAttribute("data-field");
            this.setState({
                [attr]: eve.target.value || ''
            });
            this.props.editFun(true);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onSearch(eve) {
        try {
            let attr = eve.target.getAttribute("data-field");
            this.setState({
                [attr]: eve.target.value || ''
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    OnCancel() {
        try {
            this.setState({
                isEdit: 1,
                errors: '',
                IsActive: false,
                value: '',
                IsConfirmUpdate: false,
                isUpdateExisting: false,
                OmidName: "",
                Description: "",
                searchOmid: "",
                OMID: 0

            });
            this.props.editFun(false);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentDidMount() {        
        //window.tfo_event("PMM-MC-8", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])  
        window.addEventListener('resize', this.reSizeGrid, true);
        //this.FetchConnOmidGrid();
    }

    reSizeGrid() {
        this.refs.omid_grid.gridOptions.api.sizeColumnsToFit();
    }

    async FetchConnOmidGrid() {
        try {
            var objThis = this;
            var SearchedString = "";
            request("/Common/BindOMID", 'POST')
          //  await request("/MasterScreens/GetLever?OMID=0&GroupID="+this.props.groupId+"&SearchedString=" + SearchedString + "&LanguageID=" +this.props.languageId,'GET')   
            .then(res => {
                    objThis.setState({ OmidGridData: res });
                })
                .catch(error => {
                    console.log("Error Encountered");
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SaveOmidDetails(eve) {
        try {
            const obj = this;
            let { OMID, OmidName, IsActive, IsDeleted, value, Description } = obj.state;
            let Operation = eve && OMID != 0 ? 2 : 1;
            let omidvalidation = inputValidator(value)
            let descriptionvalidation = inputValidator(Description)

if(omidvalidation === false && descriptionvalidation=== false ){

            if (obj.IsFormValid()) {
                if (eve && typeof (eve) != "boolean" && this.state.isUpdateExisting && OmidName != "") {
                    this.setState({
                        isEditPopUp: true
                    })
                    return alert(i18n.t('updateExistingorCreateNew'));
                }
                const requestData = obj.state.selectedRow;
                requestData["UserName"] =this.props.enterpriseId;
                requestData["Operation"] = Operation;
              //  requestData["OMID"] = eve ? OMID : 0;
                requestData["OmID"] = value;
                requestData["Description"] = Description;
                requestData["IsActive"] = IsActive;
                requestData["IsDeleted"] = IsDeleted;
                requestData["GroupID"] =this.props.groupId;
                requestData["LanguageID"] =this.props.languageId;

                request("/MasterScreens/UpsertOMID", 'POST', requestData)
                    .then(res => {
                        if (res.Outputcode != -1) {
                            
                            alert(res["outputmessage"]);
                            obj.FetchConnOmidGrid();
                            obj.OnCancel();
                            obj.props.editFun(false);
                        }
                        else {
                            alert(res["outputmessage"]);
                        }
                    })
                    .catch(error => {
                        console.log("Error Encountered");
                    });
            }
        }

        else{
            alert(i18n.t("PleaseEntervalidValuesintheFields"));

        }
    }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    IsFormValid() {
        try {
            let formIsValid = true;
            let errors = {};
            let reg = /^[A-Za-z\d\s]+$/;
            let regForAlphabets = /^[a-zA-Z]+$/;
            if (!this.refs.OmidName.input.value) {
                errors.OmidName = i18n.t('pleaseEnterOmid');
                alert(i18n.t('pleaseEnterOmid'));
                formIsValid = false;
            }
            // if (!this.refs.Description.value && formIsValid == true) {
            //     errors.Description = i18n.t('pleaseEnterOmidDescription');
            //     alert(i18n.t('pleaseEnterOmidDescription'));
            //     formIsValid = false;
            // }

            this.setState({ errors: errors });
            console.log(errors);
            return formIsValid;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    /*Changes for Auto Suggest */
    getSuggestions(value) {
        try {
            const stringValue = String(value);
            const escapedValue = escapeRegexCharacters(stringValue.trim());
            if (escapedValue === '') {
                let newSuggestions = this.state.OmidGridData.filter(OMID => OMID.GroupID == parseInt(this.props.groupId)
                    );
                return newSuggestions;
            } else {
                const regex = new RegExp('^' + escapedValue, 'i');
                let newSuggestions = this.state.OmidGridData.filter(OMID => regex.test(OMID.OmidName) && OMID.GroupID == parseInt(this.props.groupId)
                );
                return newSuggestions;
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onChange = (event, { newValue }) => {
        try {
            this.setState({
                value: newValue
            });
            $(event.target).attr('type', 'new');
            this.props.editFun(true);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    };

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: this.getSuggestions(value)
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
        
    };

    onSuggestionSelected(event, { suggestion }) {
        try {
            if (event.target.tagName == "SPAN") {
                $(event.target).parent().parent().parent().parent().find('input').attr('type', 'existing');
            } else {
                $(event.target).parent().parent().parent().find('input').attr('type', 'existing');
            }
            this.populateValues(suggestion);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onBlur(event) {
        try {
            let value = event.target.value.toLowerCase();
            this.state.OmidGridData.forEach(omid => {
                if (omid.OmidName.toLowerCase() == value) {
                    if (omid.GroupID == parseInt(this.props.groupId)) {
                        this.populateValues(omid);
                    }
                }
                return omid;
            })
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    /*Function closes the popup */
    closeNavigationPopUp() {
        try {
            this.setState({
                isEditPopUp: false
            })
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    passValue(value) {
        try {
            this.setState({
                isUpdateExisting: false,
                IsConfirmUpdate: value
            });
            if (value) {
                this.SaveOmidDetails(value);
            } else {
                this.SaveOmidDetails();
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }
    formatActive(params) {
        return params.value ? i18n.t('yes') : i18n.t('no');
    }
    onBtExport = () => {

        const rowData = [];
        this.gridApi.forEachNode(node => rowData.push(node.data));
        if(rowData.length>0){
       
        const newRowData = rowData.map(({OmidName,Description,IsActive}) => ({ OmidName,Description,IsActive}));

        console.log("rowData",newRowData);
        const ws = XLSX.utils.json_to_sheet(newRowData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        saveAs(new Blob([wbout], { type: "application/octet-stream" }), 'omid_data.xlsx');
        }
    };
    render() {
        var columnDefs = [];
        columnDefs = [
            { headerName: i18n.t('edit'), field: '', cellRenderer: CreateEditBox,suppressMovable: true, suppressResize: true, },
            { headerName: i18n.t('omid'), field: 'OmId', hide: true, suppressMovable: true, suppressResize: true, },
            { headerName: i18n.t('OMID'), field: 'OMID', suppressMovable: true, suppressResize: true, },
            //{ headerName: i18n.t('description'), field: 'Description', suppressMovable: true, suppressResize: true, },
         
            //{ headerName: i18n.t('isActive'), field: 'IsActive', valueFormatter: this.formatActive, suppressMovable: true, suppressResize: true, },
            
        ];

        var OmidDataGrid = (
            <div style={{ height: '400px' }} className="ag-theme-material grid-div">
                <AgGridReact ref="omid_grid" id="grd_Omid"
                    enableSorting="true"
                    paginationPageSize="10"
                    enableColResize={false}
                    columnDefs={columnDefs}
                    rowData={this.state.OmidGridData}
                    onGridReady={this.onGridReady.bind(this)}
                    rowmodeltype="pagination"
                    onCellClicked={this.onCellClicked.bind(this)}
                    enableFilter='true'
                    quickFilterText={this.state.searchOmid}
                    localeText={AgGridLocale()}
                    overlayLoadingTemplate={`<span class="ag-overlay-loading-center">${i18n.t('dataLoading')}</span>`} />
            </div>
        )

        let { value, suggestions } = this.state;

        let inputProps = {
            value,
            onChange: this.onChange,
            onBlur: this.onBlur
        }

        return (
            <div>
                <div className="box">
                    <div className="box-in">
                        <div className="col-lg-5 form-horizontal">
                            <div className="form-group">
                                <label className="control-label col-sm-4" id="lbl_OMID">{i18n.t('OMID')}:<span style={{ color: "red" }}>*</span></label>
                                <div className="col-sm-8">
                                    <Autosuggest ref="OmidName"
                                        suggestions={suggestions}
                                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                        onSuggestionSelected={this.onSuggestionSelected}
                                        getSuggestionValue={getSuggestionValue}
                                        renderSuggestion={renderSuggestion}
                                        inputProps={inputProps} />

                                   
                                </div>
                            </div>
                            {this.state.isEditPopUp && <EditConfirmation show={this.state.isEditPopUp} type={'OmidName'} editValue={this.state.OmidName}
                                passValue={this.passValue} closeCallback={this.closeNavigationPopUp} />}
                        </div>
                        {/* <div className="col-lg-5 form-horizontal">
                            <div className="form-group">
                                <label className="control-label col-sm-4" id="lbl_description"> {i18n.t('description')}:<span style={{ color: "red" }}>*</span></label>
                                <div className="col-sm-8">
                                    <textarea ref="Description" id="txtArea_description" name="Description" data-field="Description" rows="1" className="form-control" placeholder="" type="" onChange={this.onInputChange} value={this.state.Description} />
                                   
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="col-lg-5 form-horizontal">
                            <div className="form-group">
                                <label className="control-label col-sm-2" id="lbl_isActive">{i18n.t('isActive')} </label>
                                <div className="col-sm-8">
                                    <div className="commoncheckbox">
                                        <input ref="IsActive" id="chk_isActiv" type="checkbox" name="IsActive" className="chkBox" checked={this.state.IsActive} defaultChecked={true} onChange={this.ChangeIsActive} />
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="btn-group-md fright">
                            <a type="button" className="btn btn-success" onClick={this.SaveOmidDetails} >{i18n.t('save')}</a>
                            <a type="button" className="btn btn-danger" onClick={this.OnCancel} >{i18n.t('clear')}</a>
                        </div>
                        <div className="btn-group btn-group-pmmasmt">
                  <button
                    type="button"
                    id="btn_downloadAssesment"
                    title={i18n.t("DownloadbelowlistinExcelformat")}
                    className="btn btn-default btn-downld"
                    onClick={this.onBtExport}
                  >
                    <img src={downloadIcon} />
                  </button>
                </div>
                        <div className="search-div">
                            <h4 className="fleft">{i18n.t('omidDetails')} </h4>
                            <input type="text" id="txt_searchOmid" placeholder={i18n.t('searchOmid')} className="fright searchBox" data-field='searchOmid' value={this.state.searchOmid} onChange={this.onSearch} />
                        </div>
                        <div className="AsmtData data-LeadingInd">
                            <div className="box">
                                {OmidDataGrid}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
 
function mapStateToProps(state){
            return{
                languageId:state.languageId,
                enterpriseId:state.enterpriseId,
                groupId:state.groupId,
                roleName:state.roleName,
                pageName:state.roleName,
                groupName:state.groupName
            }
        }

  
OMID=connect(mapStateToProps)(withRouter(OMID));

export default OMID;

