import React from 'react';
import {
  BrowserRouter as Router,
  HashRouter as HashRouter,
  Route,
  Link,
  Switch
} from 'react-router-dom'
import { connect } from 'react-redux';
import App from '../src/App';
import request from './services/Service';
import GroupSwitchModal from './GroupSwitchModal';
import i18n from 'i18next';
//import * as actions from "./redux/actions/action"
import {setLanguageId,setPageName,setEnterpriseId,setRoleName,setGroupId,setGroupName} from "./redux/actions/action"

class Routes extends React.Component {
  constructor(props) {
    super(props);
    try {
      this.state = {
        userName: "",
        groupSwitch: "",
        groupModalFlag: false,
        roleAssosciatedUserData: [],
        activeData: []

      };
      this.Checkloggedinuser = this.Checkloggedinuser.bind(this);
      this.logout = this.logout.bind(this);
    }
    catch (error) {
      console.log("Error Encountered");
    }
  }


  Checkloggedinuser(Version) {

    if (sessionStorage.getItem("adal.idtoken") != undefined && sessionStorage.getItem("adal.idtoken") != null && sessionStorage.getItem("adal.idtoken") != "") {
      var token = sessionStorage.getItem("adal.idtoken");
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace("-", "+").replace("_", "/");
      var tokendetails = JSON.parse(window.atob(base64));
      var unique_name = tokendetails["unique_name"];
      var EnterpriseId = unique_name.split("@")[0];
      this.props.setEnterpriseId(EnterpriseId)
    }
    let LoggedInUserData = {
      EnterpriseId:EnterpriseId,
      Version:Version
    }
    request("/MasterScreens/GetLoggedInUserDetails", 'POST', LoggedInUserData)
      .then(responseJson => {
        if(responseJson[0].Status!= undefined)
        {
        var datatext = responseJson[0].Status;
        if (datatext != "Pass") {
          alert(responseJson[0].OutputMessage)
          this.logout();
        }
        else {
          this.setState({ userName: EnterpriseId });
          this.props.setLanguageId(responseJson[0].LanguageID)          
          //this.callUsageAnalyticsAPI();
        }
      this.GetRoleByLoggedinUser()
        }
        else{
          var templateopen = window.confirm(i18n.t('User not authorized to use the application'))
          if(templateopen){
            window.open('https://ts.accenture.com/:f:/r/sites/PMMUserAccessTemplate/Shared%20Documents/General?csf=1&web=1&e=LIaC3h','_blank');
         }
        }
      })
      .catch(error => {
        console.log("Error Encountered");
      });
  }

  async GetRoleByLoggedinUser() {
    try {

      var obj = this;
      let userData = {
        "LanguageID": this.props.languageId

      }
      await request("/Common/GetRoleAssociatedWithUser", 'POST', userData)
        .then(res => {
          if (res != null && res.length > 0) {

            this.setState({ roleAssosciatedUserData: res });
            let activeData = [];
            if (res.length > 0) {
              res.map(obj => {
                if (obj.IsActive == true) {
                  activeData.push(obj);
                }
              })
            }
            this.setState({ activeData });

            if (activeData.length > 1 && localStorage.getItem("GroupName") == null) 
            {
              this.setState({ groupSwitch: "true", groupModalFlag: true });
            }
            else if (activeData.length > 1 && localStorage.getItem("GroupName") != null) {
            
              activeData.forEach(obj => {
                if (localStorage.getItem("GroupName") == obj.GroupName) {
                  var ScreenNames = obj.ScreenName.split(',');
                  if (obj.RoleName == "Admin" && this.props.groupId == 1) {
                    const index = ScreenNames.indexOf("BOIConfiguration");
                    if (index > -1) {
                      ScreenNames.splice(index, 1);
                    }
                  }
                  localStorage.setItem("GroupName", obj.GroupName);

                  // to set state in redux 
                  this.props.setRoleName(obj.RoleName)
                  this.props.setPageName(ScreenNames)
                  this.props.setGroupId(obj.GroupID)
                  this.props.setGroupName(obj.GroupName)
                  this.props.setLanguageId(obj.LanguageID)

                  return(this.setState({ RoleName: obj.RoleName, pageNames: ScreenNames, groupSwitch: "false" }));
                }
              });
            }
            else {
              activeData.forEach(obj => {
                var ScreenNames = obj.ScreenName.split(',');
                if (obj.RoleName == "Admin" && this.props.groupId == 1) {
                  const index = ScreenNames.indexOf("BOIConfiguration");
                  if (index > -1) {
                    ScreenNames.splice(index, 1);
                  }
                }
                 localStorage.setItem("GroupName", obj.GroupName);

                 // to set state in redux 
                 this.props.setRoleName(obj.RoleName)
                 this.props.setPageName(ScreenNames)
                 this.props.setGroupId(obj.GroupID)
                 this.props.setGroupName(obj.GroupName)
                 this.props.setLanguageId(obj.LanguageID)

                return (this.setState({ RoleName: obj.RoleName, pageNames: ScreenNames, groupSwitch: "false" }));
              });
            }
          }
        })
        .catch(error => {
          console.log("Error Encountered");
        });
    }
    catch (error) {
      console.log("Error Encountered");
    }
  }

  componentDidMount() {
    //Shravani
    //sessionStorage.setItem("adal.idtoken","eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6ImltaTBZMnowZFlLeEJ0dEFxS19UdDVoWUJUayIsImtpZCI6ImltaTBZMnowZFlLeEJ0dEFxS19UdDVoWUJUayJ9.eyJhdWQiOiJmZjBlNmE2Zi0yOWQzLTQzNTItYjZkYi05NDdiMTg3MGQ0NWIiLCJpc3MiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC9mMzIxMWQwZS0xMjViLTQyYzMtODZkYi0zMjJiMTlhNjVhMjIvIiwiaWF0IjoxNzM5NTI4MjM1LCJuYmYiOjE3Mzk1MjgyMzUsImV4cCI6MTczOTUzMjEzNSwiYWlvIjoiQVZRQXEvOFpBQUFBMEJKMnBpSTBJVDJMUGVBUlNZUndvaGN5ZTNMaGppdUZ6UXMydXNPREdNYVl3QWlCTFdKSEhZOTh4T1pKTmJ0S1lyZVg1TS9yU0MwZmZETU01T2ZRcXdhQi9GWWVqei85dEVqQlpCQmFTS0E9IiwiYW1yIjpbInB3ZCIsIm1mYSJdLCJjYyI6IkNnRUFFaFJrY3k1a1pYWXVZV05qWlc1MGRYSmxMbU52YlJvU0NoREdLaXZQS0FjclI3TGU3N1JYZDFtakloSUtFSk1oMFZROStzRkhudDBjejlIdlJ3QXlBazVCT0FBPSIsImZhbWlseV9uYW1lIjoiU2hpcmlzaCBVcmFua2FyIiwiZ2l2ZW5fbmFtZSI6IlNocmF2YW5pIiwiaXBhZGRyIjoiMjQwMTo0OTAwOjFjNjk6MjY1YzpjMDY3OmQ4NDI6NmQ0NjphMTg1IiwibmFtZSI6IlNoaXJpc2ggVXJhbmthciwgU2hyYXZhbmkiLCJub25jZSI6ImQ3ZjcxYzJjLTMzZTQtNDFlNC05NWE2LTk5YzNkN2QzZDJiNSIsIm9pZCI6IjNhMjM4MzZiLTZmNGQtNGY2Yy1iZDk1LTg2MDAxMGQ0YWMzZCIsIm9ucHJlbV9zaWQiOiJTLTEtNS0yMS04NjE1Njc1MDEtNDEzMDI3MzIyLTE4MDE2NzQ1MzEtNDg1NDc5NSIsInJoIjoiMS5BU1lBRGgwaDgxc1N3MEtHMnpJckdhWmFJbTlxRHZfVEtWSkR0dHVVZXhodzFGc21BSjRtQUEuIiwic2lkIjoiMDAyMGFmNDktZWZhZi1mMzQxLWZmOWItMWQ4NjJlYjk4NWYxIiwic3ViIjoiazRsM1Q1UDh4d29RcHpQUFhXMk03UUlRVkNMRWFQR19ienJybGczWE54dyIsInRpZCI6ImYzMjExZDBlLTEyNWItNDJjMy04NmRiLTMyMmIxOWE2NWEyMiIsInVuaXF1ZV9uYW1lIjoicy5zaGlyaXNoLnVyYW5rYXJAZHMuZGV2LmFjY2VudHVyZS5jb20iLCJ1cG4iOiJzLnNoaXJpc2gudXJhbmthckBkcy5kZXYuYWNjZW50dXJlLmNvbSIsInV0aSI6Imt5SFJWRDM2d1VlZTNSelAwZTlIQUEiLCJ2ZXIiOiIxLjAifQ.AzPQJsBDlZ_P3ekiYA2Pn_pdWurqCoI45PzWaPlR8OykILkqS8IWg2T2TvG56BqBH7UlmfI5FikG6jDXeWx9UsDGsQ7_tLZq-lq9zW0vEgBRZGVLcRss2MIV8r1fPJi_27_AN9HS1ytrf4xZmkq-ZADxQzfTq2Rk3GI5CfjrXcQz0sXYGkvMx61yYid2HJ90mE6EFw5Gx5Bh7InVNjVSlYR0xaFWGNGU4wLQ9l43hW8EMe6XvxBU-4ZATypIUVKTg7sgaa_ch1Oyqd8eGGU99JD6HV74P0iIlUvCkP94LJ4avyMso4godcq97VKdM0jU3VFIwvo_fmTtRxK2hWnqoA");
    //Asit
    //localStorage.setItem("adal.idtoken", "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6IjJaUXBKM1VwYmpBWVhZR2FYRUpsOGxWMFRPSSIsImtpZCI6IjJaUXBKM1VwYmpBWVhZR2FYRUpsOGxWMFRPSSJ9.eyJhdWQiOiJmZjBlNmE2Zi0yOWQzLTQzNTItYjZkYi05NDdiMTg3MGQ0NWIiLCJpc3MiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC9mMzIxMWQwZS0xMjViLTQyYzMtODZkYi0zMjJiMTlhNjVhMjIvIiwiaWF0IjoxNjY1NTgxMTE0LCJuYmYiOjE2NjU1ODExMTQsImV4cCI6MTY2NTU4NTAxNCwiYWlvIjoiQVZRQXEvOFRBQUFBa284WlRlTmRLL2FjUDVTM1U0S3lGemtyd2g3c3lwV0M5dExkL0JtbGV2ZHlmZUJlc2g4NW5abEdjaG4vYVcvbXdNQkNWeDFUSmdQY3IxSUhFeTJINUk2WERSNkErd0dKd3JnaWNwamhoejA9IiwiYW1yIjpbInB3ZCIsIm1mYSJdLCJmYW1pbHlfbmFtZSI6IkphaW4iLCJnaXZlbl9uYW1lIjoiSGltYW5zaHUiLCJpcGFkZHIiOiI0OS4zNi4yMzcuMTAxIiwibmFtZSI6IkpBSU4sIEhJTUFOU0hVIiwibm9uY2UiOiJjMGNhMzMzZS0yMzFiLTRjYzctODI3Ni0xN2NiNDFjZmMxOWEiLCJvaWQiOiI4MTMxOWYxYy1mNzU3LTQyYjctYTA2ZC02NWNiYTliNTE4MzEiLCJyaCI6IjAuQVhzQURoMGg4MXNTdzBLRzJ6SXJHYVphSW05cUR2X1RLVkpEdHR1VWV4aHcxRnNtQUdZLiIsInN1YiI6ImJzZC1XUFVuOGRSRE5uaHJtNDk3RHVwWHdVRFV6QjE4RGZ6Yk1NTUZTVGsiLCJ0aWQiOiJmMzIxMWQwZS0xMjViLTQyYzMtODZkYi0zMjJiMTlhNjVhMjIiLCJ1bmlxdWVfbmFtZSI6ImguaS5qYWluQGRzLmRldi5hY2NlbnR1cmUuY29tIiwidXBuIjoiaC5pLmphaW5AZHMuZGV2LmFjY2VudHVyZS5jb20iLCJ1dGkiOiJha3ZUV0VGbFZVaWxCby1hSjg4akFRIiwidmVyIjoiMS4wIn0.jjFUIrkIRCdlMa-AXxTEx7_hKLq7JLSoGZ5TDfr9SiOVXmRHsquGSwkBLHC3rP6s6Y31hW4FzWqcWvVatnQcrlGMhsD_YyyrLN7iS65ls59wezUu74GT90whRIk9SyyWZM_tSA_Sn2yq8wPOa9VZ3UKLE7bKVke8n_4dAN60HdNvvKBzjcPiIusmgpmu9Mzlk_yvcFwMQjS-j7vF78sgeIQNETaeQ9gtln-HY8uquEwvFkg7s-B3oE0z4vS4lb8F7r12Ymnlk9S3m12zrvtVkisoAuaazRroSxkS98xKtOA6fOvvfZSqNF87URsXgsry6UQZDUCHKNc3FKmxm5cisQ");
    //localStorage.setItem("adal.idtoken","eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6Imwzc1EtNTBjQ0g0eEJWWkxIVEd3blNSNzY4MCIsImtpZCI6Imwzc1EtNTBjQ0g0eEJWWkxIVEd3blNSNzY4MCJ9.eyJhdWQiOiIzOTZjNGUxMC00Mjg0LTQ0ZTMtOWNmYS1hM2JkOTJkZGQ2YWQiLCJpc3MiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC9mMzIxMWQwZS0xMjViLTQyYzMtODZkYi0zMjJiMTlhNjVhMjIvIiwiaWF0IjoxNjM0NzM3MTYxLCJuYmYiOjE2MzQ3MzcxNjEsImV4cCI6MTYzNDc0MTA2MSwiYWlvIjoiQVVRQXUvOFRBQUFBekE0aTkrbkRRbWV1Nm9STXlPRzhPeldyRWdoNzU0dXFEZ1Z6Y0J4MWZTWXVObTM5QjNEWlFVTHF4MmR0dC8vSDNqTE9yZDNhc1BIRU51ZzdsRUlLUlE9PSIsImFtciI6WyJwd2QiLCJtZmEiXSwiZmFtaWx5X25hbWUiOiJCaGF2c2FyIiwiZ2l2ZW5fbmFtZSI6Ik1hZGh1cmEiLCJpcGFkZHIiOiIxMDMuNzMuMjE0LjE0MyIsIm5hbWUiOiJCaGF2c2FyLCBNYWRodXJhIiwibm9uY2UiOiJlMDMyYTJhNi00MjQ1LTQ1NGUtYTNiMS1mN2Y5ZTM5ZDM5YzQiLCJvaWQiOiI4ODNlOGI3ZS1iYmU0LTQ1ZjktOTc4MS04NTFkOGJlOWFkMDIiLCJvbnByZW1fc2lkIjoiUy0xLTUtMjEtODYxNTY3NTAxLTQxMzAyNzMyMi0xODAxNjc0NTMxLTQ0NzkwMjciLCJyaCI6IjAuQVhzQURoMGg4MXNTdzBLRzJ6SXJHYVphSWhCT2JEbUVRdU5FblBxanZaTGQxcTBtQU5ZLiIsInJvbGVzIjpbIkRlZmF1bHRfQWNjZXNzIl0sInN1YiI6Imo4NmhqX1FnZWh5UXQ4R1B1NGJieHR3b0dBekF0Zk53alhPOHFBQW5hMlEiLCJ0aWQiOiJmMzIxMWQwZS0xMjViLTQyYzMtODZkYi0zMjJiMTlhNjVhMjIiLCJ1bmlxdWVfbmFtZSI6Im1hZGh1cmEuYmhhdnNhckBkcy5kZXYuYWNjZW50dXJlLmNvbSIsInVwbiI6Im1hZGh1cmEuYmhhdnNhckBkcy5kZXYuYWNjZW50dXJlLmNvbSIsInV0aSI6InVnR1NDazR5ajBpSkMxSXhEMnhYQUEiLCJ2ZXIiOiIxLjAifQ.gwyCRIPvI_gOeCRM5rV-JS-63LLQuvsnLvPourCV4cZ4lJwirH8rSBz6ABeXGTuqsFYGR_7vKVZ1ENWY2o8Vp5Ph2E7tNt2Qjvyxa1rOJ8eBrFvT5TKcbedrdj2SPh1HOutBVnLcQq1JWqdY9z-_FrpM_y02_7H3dGc5hYtuvm0BDbXODjRwAdtNE-g9MtHYXe3XDQNSUbRpzFnnQODpreJtsRlXd3mmenlvI7K3vpEaG-7D1i2RPHdBbeNzraVSuCoIlDG_otR11wbujkpw2AMRIl-kVfDfCX3ms1QQFaYDIvi7Vt-Wp7v_Hxta9zoGQ13stpUGM2yvJQUHYUS2Ww");
    //localStorage.setItem("adal.idtoken","eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6ImpTMVhvMU9XRGpfNTJ2YndHTmd2UU8yVnpNYyIsImtpZCI6ImpTMVhvMU9XRGpfNTJ2YndHTmd2UU8yVnpNYyJ9.eyJhdWQiOiIzOTZjNGUxMC00Mjg0LTQ0ZTMtOWNmYS1hM2JkOTJkZGQ2YWQiLCJpc3MiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC9mMzIxMWQwZS0xMjViLTQyYzMtODZkYi0zMjJiMTlhNjVhMjIvIiwiaWF0IjoxNjQ4MTI0MTYzLCJuYmYiOjE2NDgxMjQxNjMsImV4cCI6MTY0ODEyODA2MywiYWlvIjoiQVZRQXEvOFRBQUFBaldpWDJFMTkvSnZXUEJQNzJqY0g3aGE3VUE1d1hIb0FSNjZmcVNWT3V6Unc4ZDF2K0hZb2JmU3AxeE1nVlcraFNDZXozNWtjQWppNCs5SExBa2ZnbWVkcis5NURONWlEUStHcjhRK0p3WGc9IiwiYW1yIjpbInB3ZCIsIm1mYSJdLCJmYW1pbHlfbmFtZSI6IkR3YXJrYW5hdGggQmh1dGFkYSIsImdpdmVuX25hbWUiOiJOaWtpdGEiLCJpcGFkZHIiOiIxMDMuMTUzLjE2Ni4xMTAiLCJuYW1lIjoiRHdhcmthbmF0aCBCaHV0YWRhLCBOaWtpdGEiLCJub25jZSI6IjhhMjMxNzA3LTQ2YjAtNDJkYi05NWJiLTU0ZjBkZGIwYTdhZiIsIm9pZCI6IjA0N2Q2NzNkLWQ1Y2EtNDdkNS1hZGQ0LTg0Yjc1ZDk1YzI5ZSIsIm9ucHJlbV9zaWQiOiJTLTEtNS0yMS04NjE1Njc1MDEtNDEzMDI3MzIyLTE4MDE2NzQ1MzEtNTIwNDc1OSIsInJoIjoiMC5BWHNBRGgwaDgxc1N3MEtHMnpJckdhWmFJaEJPYkRtRVF1TkVuUHFqdlpMZDFxMG1BTlUuIiwicm9sZXMiOlsiRGVmYXVsdF9BY2Nlc3MiXSwic3ViIjoiNGhoY25YOFVKWl9aX2tyamE5Vi1yUkJrcGVtTUcyQTlpSURIcTN5ajlyTSIsInRpZCI6ImYzMjExZDBlLTEyNWItNDJjMy04NmRiLTMyMmIxOWE2NWEyMiIsInVuaXF1ZV9uYW1lIjoibi5kd2Fya2FuYXRoLmJodXRhZGFAZHMuZGV2LmFjY2VudHVyZS5jb20iLCJ1cG4iOiJuLmR3YXJrYW5hdGguYmh1dGFkYUBkcy5kZXYuYWNjZW50dXJlLmNvbSIsInV0aSI6InZpVWJwTHpFalV1OHdrQ0Y4SnhyQUEiLCJ2ZXIiOiIxLjAifQ.T23jrn2PYhWmQ1uQ8OLBkehAAOn1wQRTkdJKInsSGvK82WNBA_3p-mDgsF9aie10x6LHPZu55VC2Fg7tXCJyXv7ZWYf0_LsrtRF8fAF7DBDqlA-WkbXQWjtvCv2ZYxlUUna6c9iI5I9fEm8fUi-hhk_kKkE5GEq4IG6H12jHs21kKtT23eRi9-pqKlTAAacqcXuNGX00Hj2pRDMIdYIjqnJFTheQu-8VFEJByzW1xg9qTOyQXqImqOL_y7kcwyLB_01jC9AmRSAfAlPanYMeJJWZzD0h4c0qXDMO-EA2Tm5McDf4HM2rPuk7qyJQBV_ab5Ld_ETSw8qsEIffHkw7jw");
    this.Checkloggedinuser(1);    
    //this.GetRoleByLoggedinUser();
  }

  logout() {
    try {
      window.location = "https://federation-sts-stage.accenture.com/oauth/ls/connect/endsession";
    }
    catch (error) {
      console.log("Error Encountered");
    }
  }
  componentDidUpdate(){
 }

  // callUsageAnalyticsAPI = () => {
  //        //Usage Analytic Integration
  //   if(sessionStorage["isUsageAnalyticsAPICalled"] === undefined){
  //     sessionStorage["adal.idtoken"] !== undefined && sessionStorage["adal.idtoken"] !=='' && window.tfo_ping !== undefined && window.tfo_ping (window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"]);
  //     //set value in session variable to restrict the API being called on page refresh
  //     if(sessionStorage["adal.idtoken"] !== undefined && sessionStorage["adal.idtoken"] !==''){
  //       sessionStorage["isUsageAnalyticsAPICalled"] = true
  //     }
  //   }  
  // }
  closeGroupSwitchPopup = (groupId) => {
    try {
      this.state.roleAssosciatedUserData.forEach(obj => {
        if (obj.GroupID == groupId) {
          var ScreenNames = obj.ScreenName.split(',');
          if (obj.RoleName == "Admin" && this.props.groupId == 1) {
            const index = ScreenNames.indexOf("BOIConfiguration");
            if (index > -1) {
              ScreenNames.splice(index, 1);
            }
          }
           localStorage.setItem("GroupName", obj.GroupName);

           // to set state in redux 
           this.props.setRoleName(obj.RoleName)
           this.props.setPageName(ScreenNames)
           this.props.setGroupId(obj.GroupID)
           this.props.setGroupName(obj.GroupName)
           this.props.setLanguageId(obj.LanguageID)

          return (this.setState({ RoleName: obj.RoleName, pageNames: ScreenNames, groupSwitch: "false" }));
        }
      });
    }
    catch (error) {
      console.log("Error Encountered");
    }
  }
  render() {

    return (

      <HashRouter>
        {
          this.state.groupSwitch == "true" && this.state.userName != "" ? <GroupSwitchModal roleAssosciatedUserData={this.state.activeData} show={this.state.groupModalFlag} closeCallback={(groupId) => this.closeGroupSwitchPopup(groupId)} />
            :
            this.state.groupSwitch == "false" && this.state.userName != "" ? <App closeGroupSwitchPopup={this.closeGroupSwitchPopup} userName={this.state.userName} pageNames={this.state.pageNames} />
              :
              null
        }
      </HashRouter>

    )
  }
};


function mapStateToProps(state){
 return{
    languageId:state.languageId,
    enterpriseId:state.enterpriseId,
    groupId:state.groupId,
    roleName:state.roleName,
    pageName:state.pageName,
    groupName:state.groupName,
  }
}

function mapDispatchToProps(dispatch){
  return({
    setLanguageId : (data) => dispatch(setLanguageId(data)),
    setEnterpriseId : (data) => dispatch(setEnterpriseId(data)),
    setGroupId : (data) => dispatch(setGroupId(data)),
    setGroupName : (data) => dispatch(setGroupName(data)),
    setRoleName : (data) => dispatch(setRoleName(data)),
    setPageName : (data) => dispatch(setPageName(data))
  })
}

Routes= connect(mapStateToProps,mapDispatchToProps)(Routes)
export default Routes;